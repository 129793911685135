import { Disclosure } from '@headlessui/react'
import Layout from '@@/Layout'
import Link from '@@/Link'
import Image from 'next/image'

const AuthLayout = ({ children }: any) => (
  <Layout>
    <div className="min-h-screen bg-global-background bg-cover bg-no-repeat">
      <Disclosure as="nav">
        <div className="sticky flex items-center justify-between mx-auto px-8 pt-5">
          <Link href="/login">
            <Image
              src="/images/logo.png"
              alt="Gleamer logo"
              width={125}
              height={35}
              layout="fixed"
            />
          </Link>
        </div>
      </Disclosure>
      <div className="pt-28">
        <main className="grid relative grid-cols-[1fr_1fr] max-w-6xl max-h-min mx-auto bg-white rounded-lg overflow-hidden drop-shadow-[0_35px_35px_rgba(152, 149, 149, 0.13)]">
          <Image
            className="object-cover"
            src="/images/auth-sider.jpg"
            alt="Auth sider"
            width="100%"
            height="100%"
            layout="responsive"
          />
          <div className="w-full">{children}</div>
        </main>
      </div>
    </div>
  </Layout>
)
export default AuthLayout
