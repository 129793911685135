import classNames from 'classnames'

interface H2Props {
  children?: React.ReactNode
  className?: string
}

const H2 = ({ children, className }: H2Props) => (
  <h2
    className={classNames(
      'text-2xl font-semibold text-title-color font-montserrat font-bold',
      className,
    )}
  >
    {children}
  </h2>
)

export default H2
